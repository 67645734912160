import { Button, Hero } from 'day8-ui';
import PageLayout from '../layouts/page';
import Link from 'next/link';
import Image from 'next/image';

type ErrorType = {
  statusCode: number
  error: string
}
function Custom404({ statusCode, error }: ErrorType) {
  const errorGifs = [
    'https://media.giphy.com/media/TTXevTdFfB5iE/source.gif',
    'https://media.giphy.com/media/qhoABJOROS9kQ/source.gif',
    'https://media.giphy.com/media/44LpNzZ1R5PuE/source.gif',
    'https://media.giphy.com/media/KjLpJLQJ6RBwk/giphy.gif',
    'https://media.giphy.com/media/dWM8tQEMJwdNu/source.gif'
  ];
  const mobileErrorGifs = [
    'https://media.giphy.com/media/p5gSU97t34rRe/200w_d.gif'
  ];
  return (
    <PageLayout page={{ title: { rendered: '404' } }} signupHidden>
      <Hero
        heading="Error 404"
        description="Ooops, the page you were looking for doesn't seem to exist anymore."
        cta={<Link href="/"><Button className='dui-mx-auto' >Back to our home</Button></Link>}
        imageElement={<Image src={errorGifs[Math.floor(Math.random() * errorGifs.length)]} alt="Funny boat GIF" width={1920} height={1080} />}
      />
    </PageLayout>
  )
}

export default Custom404;

